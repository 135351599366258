// https://storybook.js.org/docs/7.0/vue/configure/theming

import { addons } from '@storybook/addons'
import { create } from '@storybook/theming'
import './manager-styles.css'

const brandImage: string =
  require('../src/assets/Hydro_Logo_Horizontal_Blue.svg').default

const blue = '#444d55'

const theme = create({
  base: 'light',
  brandTitle: 'Verity',
  // brandUrl: "https://hydro.com",
  brandImage,

  colorPrimary: blue,
  colorSecondary: blue,
})

addons.setConfig({
  theme,
})
